/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** User defines the authorization model for the stewardship domain. The entire user object may be cached for performance */
export interface User {
  /**
   * REQUIRED sub is the ID for the user on AWS Cognito
   * May be empty if email is set in repo
   */
  sub: string;
  /** GENERATED name of the Stewardship Platform user (format 'users/{UUID}') */
  name: string;
  /** GENERATED the creation time of the user */
  createTime:
    | Date
    | undefined;
  /** GENERATED the update time of the user */
  updateTime:
    | Date
    | undefined;
  /** REQUIRED the privileges of the user */
  privileges:
    | Privileges
    | undefined;
  /** OPTIONAL schemes the user has permission to access */
  linkedSchemes: UserSchemeLink[];
}

/** Privileges are dynamic and do not persist in storage */
export interface Privileges {
  /**
   * REQUIRED can_vote_on_schemes is derived from:
   * - linked_schemes
   * this privilege allows a User to:
   * - submit (new or updated) votes for ballots that have not yet expired
   * - comment on ballots that have not yet expired
   */
  canVoteOnSchemes: { [key: string]: boolean };
  /**
   * REQUIRED can_export_votes_from_schemes is derived from:
   * - linked_schemes
   * this privilege allows a User to:
   * - export vote preferences and comments from a scheme
   */
  canExportVotesFromSchemes: { [key: string]: boolean };
  /**
   * REQUIRED can_view_meetings_within_schemes is derived from:
   * - linked_schemes
   * this privilege allows a User to:
   * - view meetings within a scheme
   */
  canViewMeetingsWithinSchemes: { [key: string]: boolean };
}

export interface Privileges_CanVoteOnSchemesEntry {
  key: string;
  value: boolean;
}

export interface Privileges_CanExportVotesFromSchemesEntry {
  key: string;
  value: boolean;
}

export interface Privileges_CanViewMeetingsWithinSchemesEntry {
  key: string;
  value: boolean;
}

export interface UserSchemeLink {
  /** REQUIRED scheme name (format habitats/{UUID}/schemes/{UUID}) */
  schemeName: string;
  /** REQUIRED whether the user can vote and comment on ballots of the investor in this scheme */
  canVote: boolean;
  /** REQUIRED whether the user can export a vote report based on ballots within the scheme */
  canExport: boolean;
  /** REQUIRED whether the user can view meetings within the scheme */
  canViewMeetings: boolean;
}

function createBaseUser(): User {
  return { sub: "", name: "", createTime: undefined, updateTime: undefined, privileges: undefined, linkedSchemes: [] };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sub !== "") {
      writer.uint32(10).string(message.sub);
    }
    if (message.name !== "") {
      writer.uint32(90).string(message.name);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.privileges !== undefined) {
      Privileges.encode(message.privileges, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.linkedSchemes) {
      UserSchemeLink.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sub = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.privileges = Privileges.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.linkedSchemes.push(UserSchemeLink.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      sub: isSet(object.sub) ? String(object.sub) : "",
      name: isSet(object.name) ? String(object.name) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      privileges: isSet(object.privileges) ? Privileges.fromJSON(object.privileges) : undefined,
      linkedSchemes: globalThis.Array.isArray(object?.linkedSchemes)
        ? object.linkedSchemes.map((e: any) => UserSchemeLink.fromJSON(e))
        : [],
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.sub !== "") {
      obj.sub = message.sub;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.privileges !== undefined) {
      obj.privileges = Privileges.toJSON(message.privileges);
    }
    if (message.linkedSchemes?.length) {
      obj.linkedSchemes = message.linkedSchemes.map((e) => UserSchemeLink.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.sub = object.sub ?? "";
    message.name = object.name ?? "";
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.privileges = (object.privileges !== undefined && object.privileges !== null)
      ? Privileges.fromPartial(object.privileges)
      : undefined;
    message.linkedSchemes = object.linkedSchemes?.map((e) => UserSchemeLink.fromPartial(e)) || [];
    return message;
  },
};

function createBasePrivileges(): Privileges {
  return { canVoteOnSchemes: {}, canExportVotesFromSchemes: {}, canViewMeetingsWithinSchemes: {} };
}

export const Privileges = {
  encode(message: Privileges, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.canVoteOnSchemes).forEach(([key, value]) => {
      Privileges_CanVoteOnSchemesEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    Object.entries(message.canExportVotesFromSchemes).forEach(([key, value]) => {
      Privileges_CanExportVotesFromSchemesEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
    });
    Object.entries(message.canViewMeetingsWithinSchemes).forEach(([key, value]) => {
      Privileges_CanViewMeetingsWithinSchemesEntry.encode({ key: key as any, value }, writer.uint32(66).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Privileges {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivileges();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = Privileges_CanVoteOnSchemesEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.canVoteOnSchemes[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = Privileges_CanExportVotesFromSchemesEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.canExportVotesFromSchemes[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = Privileges_CanViewMeetingsWithinSchemesEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.canViewMeetingsWithinSchemes[entry8.key] = entry8.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Privileges {
    return {
      canVoteOnSchemes: isObject(object.canVoteOnSchemes)
        ? Object.entries(object.canVoteOnSchemes).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
      canExportVotesFromSchemes: isObject(object.canExportVotesFromSchemes)
        ? Object.entries(object.canExportVotesFromSchemes).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
      canViewMeetingsWithinSchemes: isObject(object.canViewMeetingsWithinSchemes)
        ? Object.entries(object.canViewMeetingsWithinSchemes).reduce<{ [key: string]: boolean }>(
          (acc, [key, value]) => {
            acc[key] = Boolean(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: Privileges): unknown {
    const obj: any = {};
    if (message.canVoteOnSchemes) {
      const entries = Object.entries(message.canVoteOnSchemes);
      if (entries.length > 0) {
        obj.canVoteOnSchemes = {};
        entries.forEach(([k, v]) => {
          obj.canVoteOnSchemes[k] = v;
        });
      }
    }
    if (message.canExportVotesFromSchemes) {
      const entries = Object.entries(message.canExportVotesFromSchemes);
      if (entries.length > 0) {
        obj.canExportVotesFromSchemes = {};
        entries.forEach(([k, v]) => {
          obj.canExportVotesFromSchemes[k] = v;
        });
      }
    }
    if (message.canViewMeetingsWithinSchemes) {
      const entries = Object.entries(message.canViewMeetingsWithinSchemes);
      if (entries.length > 0) {
        obj.canViewMeetingsWithinSchemes = {};
        entries.forEach(([k, v]) => {
          obj.canViewMeetingsWithinSchemes[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Privileges>, I>>(base?: I): Privileges {
    return Privileges.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Privileges>, I>>(object: I): Privileges {
    const message = createBasePrivileges();
    message.canVoteOnSchemes = Object.entries(object.canVoteOnSchemes ?? {}).reduce<{ [key: string]: boolean }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Boolean(value);
        }
        return acc;
      },
      {},
    );
    message.canExportVotesFromSchemes = Object.entries(object.canExportVotesFromSchemes ?? {}).reduce<
      { [key: string]: boolean }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Boolean(value);
      }
      return acc;
    }, {});
    message.canViewMeetingsWithinSchemes = Object.entries(object.canViewMeetingsWithinSchemes ?? {}).reduce<
      { [key: string]: boolean }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Boolean(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBasePrivileges_CanVoteOnSchemesEntry(): Privileges_CanVoteOnSchemesEntry {
  return { key: "", value: false };
}

export const Privileges_CanVoteOnSchemesEntry = {
  encode(message: Privileges_CanVoteOnSchemesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Privileges_CanVoteOnSchemesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivileges_CanVoteOnSchemesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Privileges_CanVoteOnSchemesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: Privileges_CanVoteOnSchemesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Privileges_CanVoteOnSchemesEntry>, I>>(
    base?: I,
  ): Privileges_CanVoteOnSchemesEntry {
    return Privileges_CanVoteOnSchemesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Privileges_CanVoteOnSchemesEntry>, I>>(
    object: I,
  ): Privileges_CanVoteOnSchemesEntry {
    const message = createBasePrivileges_CanVoteOnSchemesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBasePrivileges_CanExportVotesFromSchemesEntry(): Privileges_CanExportVotesFromSchemesEntry {
  return { key: "", value: false };
}

export const Privileges_CanExportVotesFromSchemesEntry = {
  encode(message: Privileges_CanExportVotesFromSchemesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Privileges_CanExportVotesFromSchemesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivileges_CanExportVotesFromSchemesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Privileges_CanExportVotesFromSchemesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: Privileges_CanExportVotesFromSchemesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Privileges_CanExportVotesFromSchemesEntry>, I>>(
    base?: I,
  ): Privileges_CanExportVotesFromSchemesEntry {
    return Privileges_CanExportVotesFromSchemesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Privileges_CanExportVotesFromSchemesEntry>, I>>(
    object: I,
  ): Privileges_CanExportVotesFromSchemesEntry {
    const message = createBasePrivileges_CanExportVotesFromSchemesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBasePrivileges_CanViewMeetingsWithinSchemesEntry(): Privileges_CanViewMeetingsWithinSchemesEntry {
  return { key: "", value: false };
}

export const Privileges_CanViewMeetingsWithinSchemesEntry = {
  encode(message: Privileges_CanViewMeetingsWithinSchemesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Privileges_CanViewMeetingsWithinSchemesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivileges_CanViewMeetingsWithinSchemesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Privileges_CanViewMeetingsWithinSchemesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: Privileges_CanViewMeetingsWithinSchemesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Privileges_CanViewMeetingsWithinSchemesEntry>, I>>(
    base?: I,
  ): Privileges_CanViewMeetingsWithinSchemesEntry {
    return Privileges_CanViewMeetingsWithinSchemesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Privileges_CanViewMeetingsWithinSchemesEntry>, I>>(
    object: I,
  ): Privileges_CanViewMeetingsWithinSchemesEntry {
    const message = createBasePrivileges_CanViewMeetingsWithinSchemesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseUserSchemeLink(): UserSchemeLink {
  return { schemeName: "", canVote: false, canExport: false, canViewMeetings: false };
}

export const UserSchemeLink = {
  encode(message: UserSchemeLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.schemeName !== "") {
      writer.uint32(10).string(message.schemeName);
    }
    if (message.canVote === true) {
      writer.uint32(16).bool(message.canVote);
    }
    if (message.canExport === true) {
      writer.uint32(24).bool(message.canExport);
    }
    if (message.canViewMeetings === true) {
      writer.uint32(32).bool(message.canViewMeetings);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSchemeLink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSchemeLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.canVote = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.canExport = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.canViewMeetings = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSchemeLink {
    return {
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
      canVote: isSet(object.canVote) ? Boolean(object.canVote) : false,
      canExport: isSet(object.canExport) ? Boolean(object.canExport) : false,
      canViewMeetings: isSet(object.canViewMeetings) ? Boolean(object.canViewMeetings) : false,
    };
  },

  toJSON(message: UserSchemeLink): unknown {
    const obj: any = {};
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    if (message.canVote === true) {
      obj.canVote = message.canVote;
    }
    if (message.canExport === true) {
      obj.canExport = message.canExport;
    }
    if (message.canViewMeetings === true) {
      obj.canViewMeetings = message.canViewMeetings;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSchemeLink>, I>>(base?: I): UserSchemeLink {
    return UserSchemeLink.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserSchemeLink>, I>>(object: I): UserSchemeLink {
    const message = createBaseUserSchemeLink();
    message.schemeName = object.schemeName ?? "";
    message.canVote = object.canVote ?? false;
    message.canExport = object.canExport ?? false;
    message.canViewMeetings = object.canViewMeetings ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
