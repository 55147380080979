import styled, { css } from 'styled-components'
import { Caption, Stack } from '@tumelo/designsystem'
import Link from 'next/link'
import React from 'react'

interface HeaderLinkProps {
  href: string
  label: string
  current?: boolean
  className?: string
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({ href, label, current, className }) => {
  return (
    <Link href={href}>
      <StyledCaption $semibold $current={current} mb="0" className={className}>
        <Stack
          as="span"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap="0.8rem"
          alignContent="center"
        >
          {label}
        </Stack>
      </StyledCaption>
    </Link>
  )
}

interface StyledCaptionProps {
  $current?: boolean
}

const StyledCaption = styled(Caption)<StyledCaptionProps>`
  ${({ theme, $current }) => {
    return css`
      cursor: pointer;
      color: ${$current ? theme.colors.info.light : theme.colors.info.medium};
      ${$current && `font-weight: ${theme.fontWeights.bold}`};
      &:hover {
        text-decoration: underline;
      }
      svg {
        fill: ${$current ? theme.colors.info.light : theme.colors.info.medium};
      }
    `
  }}
`
